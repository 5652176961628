import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'react-bootstrap-modal';

import { ReactComponent as CrossIcon } from 'images/cross.svg';

import TRMSGS from 'constants/Translations/Localization';


 const InfoModal = (props) =>  {
  const { isOpen,toggle,isNeedCorrection} = props;
  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      backdrop="static"
      hide={() => toggle()}
      toggle={()=>toggle()}
    >
      <Modal.Header>
        <div className="f3 flex justify-between">
          <p className="mb0 mt3">{TRMSGS.dailyTimecard}</p>
          <p className="mb0 mt3 back-arrow" onClick={()=>toggle()}><CrossIcon height="14" width="14"/></p>
        </div>

      </Modal.Header>
      <Modal.Body className="mt3 mb3 ph4">
        {isNeedCorrection ?
          <div className="f3">{TRMSGS.timecardApproval}</div>
          :
          <div className="f3 center">{TRMSGS.timecardApproved}</div>
        }
      </Modal.Body>
    </Modal>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default InfoModal;
